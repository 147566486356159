import { Component, OnInit, Input } from '@angular/core';
import { Card } from 'ngx-pokemontcg-io';
import { PproxyService } from '../pproxy.service';

@Component({
  selector: 'app-pokemoncard',
  templateUrl: './pokemoncard.component.html',
  styleUrls: ['./pokemoncard.component.css']
})
export class PokemoncardComponent implements OnInit {

  @Input()
  card: Card;

  count: number = 0;

  constructor(public proxy: PproxyService) {
    this.proxy.toggleEvent.subscribe(() => {
      this.update();
    });

    this.proxy.updateEvent.subscribe(() => {
      this.update();
    });

   }

  ngOnInit() {
    this.update();
  }

  incCount() {
    this.count++;
    this.proxy.addCard(this.card);
  }

  decCount() {
    if (this.count - 1 >= 0) {
      this.count--;
      this.proxy.removeCard(this.card);
    }
  }

  update() {
    this.count = this.proxy.getCount(this.card);
  }

}
