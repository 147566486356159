import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlshortenerService {

  constructor(private http: HttpClient) { }

  shortenUrl(url: string) {
    return new Promise(resolve => {
      this.http.post('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=' + environment.firebase.apiKey, {
        longDynamicLink: " https://samsproxy.page.link/?link=" + url
      }).subscribe(res => {
        resolve(res);
      })
    })
  }

}
