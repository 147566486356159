import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TCGSet, Card } from '../Model/YgoApiResponse';
import { YgoapiService } from '../ygoapi.service';
import { ProxyService } from '../proxy.service';

@Component({
  selector: 'app-set-view',
  templateUrl: './set-view.component.html',
  styleUrls: ['./set-view.component.css']
})
export class SetViewComponent implements OnInit {

  sets: TCGSet[] = [];
  selectedSet: TCGSet;

  cards: Card[] = [];
  
  showProxies: boolean = false;

  searchText: string = "";

  constructor(private ygo: YgoapiService,
    public proxy: ProxyService,
    private change: ChangeDetectorRef) { 
    this.ygo.getSets().subscribe(res => {

      let sets: TCGSet[] = res as TCGSet[];

      sets.sort((a,b) => {
        let date1 = new Date(a.tcg_date);
        let date2 = new Date(b.tcg_date);
        return +date2.getTime() - +date1.getTime();
      });

      this.sets = sets.reverse();
      this.selectedSet = this.sets[0];
      this.updateCards();
    })
  }

  updateCards() {
    this.ygo.getCardsFromSet(this.selectedSet.set_name).subscribe(res => {
      this.cards = res['data'];
      this.cards[0].card_images[0].image_url_small;
    })
  }

  search;

  searchCard() {

    if (this.search) {
      clearTimeout(this.search);
    }

    if (this.searchText == '') {
      this.updateCards();
    } else {
      this.search = setTimeout(() => {
        this.ygo.searchForCards(this.searchText).subscribe(res => {
          this.cards = res['data'];
        }, err => {
          this.updateCards();
        })
      }, 200)
  
    }
  }

  printProxies() {
    let remember = this.showProxies;
    this.showProxies = true;

    this.change.detectChanges();

    window.print();
    setTimeout(() => {
      this.showProxies = remember;
    }, 100);
  }

  toggleProxies() {
    this.showProxies = !this.showProxies;
  }

  removeCard(i) {
    this.proxy.removeCardAtIndex(i);
  }

  ngOnInit() {
    document.title = "Yu-Gi-Oh Proxy Maker";
  }

}
