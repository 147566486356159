import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Set } from 'ngx-pokemontcg-io';
import { PproxyService } from '../pokemon/pproxy.service';

export enum ImportModes {
  BEN,
  PTCGO,
  GRENINJA
};

@Component({
  selector: 'app-benimport',
  templateUrl: './benimport.component.html',
  styleUrls: ['./benimport.component.css']
})



export class BenimportComponent implements OnInit {

  @Input()
  importMode: Boolean = false;

  @Input()
  usertext: string = "";

  @Input()
  sets: Set[] = [];

  @Input()
  mode: ImportModes;

  constructor(private modal: NgbModal,
    private proxy: PproxyService) { }

  ngOnInit() {
  }

  close() {

    if (this.importMode) {

      switch (this.mode) {
        case ImportModes.BEN:
          this.proxy.benImport(this.usertext); 
          break;
        case ImportModes.PTCGO:
          this.proxy.ptcgoImport(this.usertext);
          break;
          case ImportModes.GRENINJA:
            this.proxy.greninjaImport(this.usertext);
            break;
      }
    } 

    this.modal.dismissAll();
  }

}
