import { Component, OnInit, Input } from '@angular/core';
import { Card } from '../Model/YgoApiResponse';
import { ProxyService } from '../proxy.service';

@Component({
  selector: 'app-ygocard',
  templateUrl: './ygocard.component.html',
  styleUrls: ['./ygocard.component.css']
})
export class YgocardComponent implements OnInit {

  @Input()
  card: Card;

  count: number = 0;

  constructor(public proxy: ProxyService) {
    this.proxy.updateEvent.subscribe(() => {
      this.update();
    })
   }

  ngOnInit() {
   this.update()
  }

  update() {
    this.count = this.proxy.getCount(this.card);
  }

  incCount() {
    this.count++;
    this.proxy.addCard(this.card);
  }

  decCount() {
    if (this.count - 1 >= 0) {
      this.count--;
      this.proxy.removeCard(this.card);
    }
  }

}
