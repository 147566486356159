import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SetViewComponent } from './ygo/ygoset-view/set-view.component';
import { PokemonviewComponent } from './pokemon/pokemonview/pokemonview.component';


const routes: Routes = [{path: 'ygo', component: SetViewComponent},
{path: 'pokemon', component: PokemonviewComponent},
{path: '*', component: PokemonviewComponent},
{path: '', component: PokemonviewComponent}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
