import { Injectable } from '@angular/core';
import { Card, PokemontcgIoService, Set } from 'ngx-pokemontcg-io';
import { Subject, forkJoin } from 'rxjs';
import { UrlshortenerService } from '../urlshortener.service';

@Injectable({
  providedIn: 'root'
})
export class PproxyService {

  public proxySet: Card[] = [];
  public toDisplay: Card[] = [];

  public proxyMode = true;

  public sets: Set[] = [];

  removeHistory = [];

  public updateEvent = new Subject<any>();
  public toggleEvent = new Subject<any>();

  constructor(private api: PokemontcgIoService,
    private url: UrlshortenerService) { }

  getCount(card: Card) {
    let count = 0;
    for (let i = 0; i < this.proxySet.length; i++) {
      if (JSON.stringify(this.proxySet[i]) === JSON.stringify(card)) {
        count++;
      }
    }

    return count;
  }

  addCard(card: Card) {
    this.proxySet.push(card);
    this.updateEvent.next('');
  }

  removeCard(card: Card) {
    let index = this.proxySet.indexOf(card);
    this.addHistoy({ card: card, index: index, action: 'remove' });
    this.proxySet.splice(index, 1);
    this.updateEvent.next('');
  }

  addHistoy(action) {
    if (this.removeHistory.length >= 20) {
      this.removeHistory.shift();
    }

    this.removeHistory.push(action);
  }

  undo() {
    let action = this.removeHistory.pop();
    if (action) {
      if (action.action == 'remove') {
        this.proxySet.splice(action.index, 0, action.card);
      } else if (action.action == "clear") {
        this.proxySet = action.cardSet;
      }
      this.updateEvent.next('');
    }
  }

  clearCards() {
    this.addHistoy({ cardSet: JSON.parse(JSON.stringify(this.proxySet)), action: 'clear' })
    this.proxySet.length = 0;
    this.updateEvent.next('true');
  }

  removeCardViaIndex(index: number) {
    let card = this.proxySet.splice(index, 1);
    this.addHistoy({ card: card[0], index: index, action: 'remove' });
    this.updateEvent.next('true');
  }


  getMap() {
    let map = [];

    for (let i = 0; i < this.proxySet.length; i++) {
      let card = this.proxySet[i];

      let found = false;

      for (let j = 0; j < map.length; j++) {
        if (map[j].card.id == card.id) {
          map[j].count++;
          found = true;
        }
      }

      if (!found) {
        map.push({ card: card, count: 1 });
      }

    }

    return map;

  }


  generateUrl() {
    let map = this.getMap();

    let url = "";

    map.forEach(element => {
      url += (element.card.id + "_" + element.count + ',');
    })

    url = url.slice(0, -1);

    url = window.location.protocol + "//" + (window.location.host + "?proxies=" + url);

    return this.url.shortenUrl(url);

  }

  benExport() {
    let map = this.getMap();

    let exportText = "Add a Title!\n";

    for (let i = 0; i < map.length; i++) {
      exportText += (map[i].card.id + "\t" + map[i].count + "\n");
    }

    return exportText.trim();


  }




  benImport(importText: string) {
    let convert = importText;

    let lines = convert.split('\n');
    let conv = [];

    lines.forEach(line => {

      if (line.includes('\t')) {
        for (let i = 0; i < this.sets.length; i++) {
          if (line.match(new RegExp("\\b" + this.sets[i].ptcgoCode + "\\b", "i"))) {
            line = line.replace(this.sets[i].ptcgoCode, this.sets[i].id)
            conv.push(line);
            break;
          } else if (line.match(new RegExp("\\b" + this.sets[i].id + "\\b", "i"))) {
            conv.push(line);
            break;
          }
        }
      }

    });

    convert = conv.join('\n');
    convert = convert.replace(/\t/g, '_');
    convert = convert.replace(/\n/g, ',');
    convert = convert.replace(/ /g, '-')
    this.fromUrl(convert);
  }

  ptcgoExport() {
    let deck = this.getMap();
    let exp = "";

    for (let i = 0; i < deck.length; i++) {
      let card: Card = deck[i].card;
      let set = this.getSetFromName(card.set.name);
      exp += ("* " + deck[i].count + " " + card.name + " " + set.ptcgoCode + " " + deck[i].card.number + "\n");
    }

    return exp;

  }

  tabletopExport() {
    let deck = this.getMap();
    let exp = "";

    for (let i = 0; i < deck.length; i++) {
      let card: Card = deck[i].card;
      let set = this.getSetFromName(card.set.name);

      if (set.name == "Rising Rivals") {
        exp += (deck[i].count + " " + card.name + " RR2" + " " + deck[i].card.number + "\n");

      } else {
        exp += (deck[i].count + " " + card.name + " " + set.ptcgoCode + " " + deck[i].card.number + "\n");
      }




    }

    return exp;
  }

  greninjaExport() {
    let deck = this.getMap();
    let exp = "QTY,Name,Type,URL";

    for (let i = 0; i < deck.length; i++) {
      let card: Card = deck[i].card;

      let cardName = card.name ?? "placeholder";

      if (!card.name) {
        try {

          cardName = card.images.large.split("/")[5
          ].split("-")[0].replace("_", " ");

        } catch (e) {
          cardName = "placeholder";
        }
      }



      exp += "\r\n";
      exp += deck[i].count + "," + cardName + ", placeholder," + card.images.large
    }

    return exp;


  }

  greninjaImport(importText: string) {
    let lines = importText.split('\n');

    this.proxySet = [];

    for (let i = 1; i < lines.length; i++) {

      let split = lines[i].split(',');
      let times = Number.parseInt(split[0]);

      for (let j = 0; j < times; j++)

        this.proxySet.push({
          name: split[1],
          images: {
            large: split[3],
            small: null
          }
        })
    }

    this.proxyMode = true;


  }

  ptcgoImport(importText: string) {
    let lines = importText.split('\n');
    let imp = "";

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].length > 2) {
        if (lines[i][0] == '*' && lines[i][1] == ' ') {
          let lineSplit = lines[i].split(' ');
          let count = lineSplit[1];
          let important = lineSplit.splice(-2);
          let set = important[0];

          if (set != "Energy") {

            let setFound = this.getSetFromPtcgoCode(set);

            if (setFound) {
              set = setFound.id;
              let number = important[1];
              let card = set + "-" + number;
              imp += (card + "_" + count + ",");
            } else {
              console.warn('set not found: ' + set);
            }


          }



        }
      }

    }

    this.fromUrl(imp);

  }

  getSetFromName(setName: string) {
    for (let i = 0; i < this.sets.length; i++) {
      if (this.sets[i].name === setName) {
        return this.sets[i];
      }
    }
    return null;
  }

  getSetFromPtcgoCode(code: string) {
    for (let i = 0; i < this.sets.length; i++) {
      if (this.sets[i].ptcgoCode === code) {
        return this.sets[i];
      }
    }
    return null;
  }

  fromUrl(url: string) {

    let elements = url.split(',');
    let observable = [];

    elements.forEach(ele => {
      let splitted = ele.split('_');
      if (splitted.length == 2) {
        observable.push(this.api.getCard(splitted[0]));
      }
    })

    forkJoin(observable).subscribe(res => {
      elements.forEach(element => {
        let split = element.split('_');
        let card = this.findCardInArray(res, split[0]);
        if (card) {
          for (let i = 0; i < +split[1]; i++) {
            this.proxySet.push(card);
          }
        }
      })
      this.updateEvent.next('');
    })
  }

  findCardInArray(arr, id) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id.toString().toUpperCase() == id.toUpperCase()) {
        return arr[i];
      }
    }
    return null;
  }

  exportAsJson() {
    alert(JSON.stringify(this.proxySet))
  }

  importAsJson() {
    var inp = prompt('Import Cards');

    this.proxySet = JSON.parse(inp);
  }

}
