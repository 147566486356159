import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CardSet } from './Model/YgoApiResponse';

@Injectable({
  providedIn: 'root'
})
export class YgoapiService {

  constructor(private http: HttpClient) { }


  public getSets(): Observable<Object> {
    return this.http.get('https://db.ygoprodeck.com/api/v7/cardsets.php');
  }

  public getCardsFromSet(set: string) {
    return this.http.get('https://db.ygoprodeck.com/api/v7/cardinfo.php?cardset=' + set + '&view=List');
  }

  public searchForCards(card: string) {
    return this.http.get('https://db.ygoprodeck.com/api/v7/cardinfo.php?fname=' + encodeURIComponent(card));
  }

}
