import { Component } from '@angular/core';
import { YgoapiService } from './ygo/ygoapi.service';
import { CardSet, Card, TCGSet } from './ygo/Model/YgoApiResponse';
import { PokemontcgIoService } from 'ngx-pokemontcg-io';
import { Analytics } from 'firebase/analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'YuGiOh Tools';
  
  constructor(private api: PokemontcgIoService) {
    this.api.apiKey = "1a0708eb-fda9-4f22-9fed-523b72f442a6"
      
  }

}
