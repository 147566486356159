import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { SetViewComponent } from './ygo/ygoset-view/set-view.component';
import { PokemonviewComponent } from './pokemon/pokemonview/pokemonview.component';
import { PokemoncardComponent } from './pokemon/pokemoncard/pokemoncard.component';
import { AppRoutingModule } from './app-routing.module';
import { YgocardComponent } from './ygo/ygocard/ygocard.component';
import {NgbModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BenimportComponent } from './benimport/benimport.component';
import { TestDirective } from './test.directive';
import { ToasterComponent } from './toaster/toaster.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgxPokemonTcgIoModule } from 'ngx-pokemontcg-io';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAnalytics,getAnalytics,ScreenTrackingService,UserTrackingService } from '@angular/fire/analytics';
import { providePerformance,getPerformance } from '@angular/fire/performance';



@NgModule({
    declarations: [
        AppComponent,
        SetViewComponent,
        PokemonviewComponent,
        PokemoncardComponent,
        YgocardComponent,
        BenimportComponent,
        TestDirective,
        ToasterComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        NgbModule,
        NgxPokemonTcgIoModule
    ],
    providers: [
    ScreenTrackingService,UserTrackingService
  ],
    bootstrap: [AppComponent]
})
export class AppModule { }
