import { Injectable } from '@angular/core';
import { Card } from './Model/YgoApiResponse';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProxyService {

  public proxyMode = true;
  public proxySet: Card[] = [];

  public updateEvent = new Subject<any>();

  constructor() { }

  addCard(card: Card) {
    this.proxySet.push(card);
  }

  removeCard(card: Card) {
    let index = this.proxySet.findIndex(card => card === card);
    this.proxySet.splice(index, 1);
    this.updateEvent.next('true');
  }

  removeCardAtIndex(i: number) {
    this.proxySet.splice(i, 1);
    this.updateEvent.next('true');
  }

  getCount(card: Card) {
    let count = 0;
    for(let i = 0; i < this.proxySet.length; i++) {
      if (JSON.stringify(this.proxySet[i]) === JSON.stringify(card)) {
        count++;
      }
    }

    return count;
  }


}
